import { type Theme } from '@mui/material/styles'
import { buttonColorStyles, buttonSizeStyles } from './Button'

export const LoadingButton = (theme: Theme) => {
	return {
		MuiLoadingButton: {
			defaultProps: {
				disableElevation: true,
				variant: 'contained',
				color: 'primary',
				size: 'xLarge',
			},
			variants: [...buttonSizeStyles, ...buttonColorStyles],
		},
	}
}
