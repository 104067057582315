import { RecoilEnv } from 'recoil'

export const IS_STG = process.env.NEXT_PUBLIC_STAGE === 'stg'

export const IS_STG2 = process.env.NEXT_PUBLIC_STAGE === 'release_stg'

export const IS_LOCAL = process.env.NEXT_PUBLIC_STAGE === 'local'

export const IS_DEV = process.env.NEXT_PUBLIC_STAGE === 'dev' || IS_LOCAL

export const IS_PROD = process.env.NEXT_PUBLIC_STAGE === 'prd'

export const fullDate12FormatWithDay = 'YYYY. M. D (ddd) A h:mm'

export const fullDate24Format = 'YYYY. M. D H:mm'

export const simpleDateFormat = 'YYYY. M. D'

export const simpleDateFormatPadded = 'YYYY. MM. DD'

export const simpleDateFormatWithDay = 'YYYY. M. D (ddd)' // same as 'YYYY. M. D (dd)' in Korean

export const PHONE_NUMBER = '02-6941-0288'

export const HELP_EMAIL = 'study@briiidgeproject.com'

export const SERVER_URL = IS_DEV
	? 'https://bridge.knowre-dev.com'
	: IS_STG
		? 'https://stg.briiidgeproject.com'
		: IS_PROD
			? 'https://briiidgeproject.com'
			: 'localhost:3000'

// nextjs + recoil 사용시 개발환경에서 리코일의 재선언으로 인해 중복키 에러 발생하여 에러 로그 숨김
RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = !IS_DEV
