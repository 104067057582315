import { type Theme } from '@mui/material/styles'
import { Button } from './Button'
import { Typography } from './Typography'
import { Checkbox } from './Checkbox'
import { IconButton } from './IconButton'
import { Fab } from './Fab'
import { ToggleButton } from './ToggleButton'
import { ButtonGroup } from './ButtonGroup'
import { Radio } from './Radio'
import { Switch } from './Switch'
import { Slider } from './Slider'
import { TextField } from './TextField'
import { ToggleButtonGroup } from './ToggleButtonGroup'
import { Stack } from './Stack'
import { Select } from './Select'
import { LoadingButton } from './LoadingButton'

export const ComponentsOverrides = (theme: Theme): any => {
	return Object.assign(
		Button(theme),
		ButtonGroup(theme),
		Fab(theme),
		IconButton(theme),
		Radio(theme),
		Slider(theme),
		Switch(theme),
		TextField(theme),
		ToggleButton(theme),
		ToggleButtonGroup(theme),
		Typography(theme),
		Checkbox(theme),
		Stack(theme),
		Select(theme),
		LoadingButton(theme),
	)
}
