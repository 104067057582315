import { useState, useEffect } from 'react'

const useWindowDimensions = () => {
	const [dimensions, setDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
		scale: 1,
		fontScale: 1,
	})

	useEffect(() => {
		const handleResize = () => {
			setDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
				scale: 1,
				fontScale: 1,
			})
		}

		window.addEventListener('resize', handleResize)

		return () => { window.removeEventListener('resize', handleResize) }
	}, [])

	return dimensions
}

export default useWindowDimensions
