import { Stack } from '@bridge/ui'
import { CircularProgress } from '@mui/material'
import colors from 'app/colors'

export default function LoadingSpinner () {
	return (
		<Stack
			display="flex"
			alignItems="center"
			justifyContent="center"
			position="absolute"
			sx={{ inset: 0 }}
			width="100%"
			height="100%"
		>
			<CircularProgress size='20px' sx={{ color: colors.green400 }} />
		</Stack>
	)
}
