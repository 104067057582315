import { Checkbox as MuiCheckbox, type CheckboxProps } from '@mui/material'
import { styled, useThemeProps } from '@mui/material/styles'
import { getDisableHoverStyle } from '../theme/overrides/Checkbox'

// mui Checkbox에 disableHover prop을 추가했으나 아래와 같은 에러가 뜸:
// "React does not recognize the `disableHover` prop on a DOM element."
// 이 에러 메세지가 거슬린다면 아래 커스텀 Checkbox를 쓰세요.
// p.s. disableHover은 disableRipple과 함께 사용할 때 적용됨.

const options = {
	shouldForwardProp: (prop: PropertyKey) => prop !== 'disableHover',
}

const StyledCheckbox = styled(MuiCheckbox, options)<CheckboxProps>(({ theme, disableHover }) => {
	const disableHoverStyle = getDisableHoverStyle(theme)

	return {
		...(disableHover && disableHoverStyle),
	}
})

export default function Checkbox (inProps: CheckboxProps) {
	const props = useThemeProps({
		props: inProps,
		name: 'MuiCheckbox',
	})

	const { disableHover, ...rest } = props

	return (
		<StyledCheckbox disableHover={disableHover} {...rest} />
	)
}
