import { type ComponentProps } from 'react'
import { Typography } from '@mui/material'
import { isMobile } from 'react-device-detect'

const WordBreakText = ({ children, ...props }: ComponentProps<typeof Typography>) => {
	const { sx, ...rest } = props

	return (
		<Typography
			{...rest}
			sx={{
				...sx,
				wordBreak: isMobile ? 'normal' : 'keep-all',
				whiteSpace: 'pre-wrap',
				overflowWrap: 'break-word',
			}}
		>
			{children}
		</Typography>
	)
}

export default WordBreakText
