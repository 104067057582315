import Image from 'next/image'

const Svg = ({ src, asset, alt = '', ...props }) => {
	const { width, height, ...rest } = props

	return (
		<Image
			width={removePx(width)}
			height={removePx(height)}
			src={src}
			alt={alt ?? ''}
			{...rest}
		/>
	)
}

export default Svg

function removePx (value: string | number) {
	if (typeof value === 'string') {
		return parseInt(value.replace('px', ''))
	}

	return value
}
