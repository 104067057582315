/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type ButtonProps } from '@mui/material'
import { type SxProps, type Theme } from '@mui/material/styles'
import { colors as themeColors } from '../colors'
import { TEXT } from '../legacy-native-base'

declare module '@mui/material/Button' {
	interface ButtonPropsSizeOverrides {
		xLarge: true
		tiny: true
	}

	interface ButtonPropsColorOverrides {
		greyScale: true
	}
}

type StyleOption = {
	props: ButtonProps
	style: SxProps<Theme>
}

export const buttonSizeStyles: StyleOption[] = [
	{
		props: { size: 'xLarge' },
		style: {
			height: 56,
			borderRadius: 12,
			...TEXT.sizes.btn_l_700,
		},
	},
	{
		props: { size: 'large' },
		style: {
			height: 48,
			borderRadius: 12,
			...TEXT.sizes.btn_l_700,
		},
	},
	{
		props: { size: 'medium' },
		style: {
			height: 40,
			borderRadius: 12,
			...TEXT.sizes.btn_m_700,
		},
	},
	{
		props: { size: 'small' },
		style: {
			height: 32,
			borderRadius: 12,
			px: 8,
			...TEXT.sizes.btn_s_700,
		},
	},
	{
		props: { size: 'tiny' },
		style: {
			height: 28,
			borderRadius: 12,
			px: 8,
			...TEXT.sizes.btn_s_700,
		},
	},
]

export const buttonColorStyles: StyleOption[] = [
	{
		props: {
			variant: 'outlined',
			color: 'primary',
			disabled: false,
		},
		style: {
			backgroundColor: themeColors.green50,
			'&:hover': {
				backgroundColor: themeColors.green100,
			},
		},
	},
	{
		props: { variant: 'text' },
		style: {
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
	},
	{
		props: {
			variant: 'contained',
			color: 'greyScale',
		},
		style: {
			backgroundColor: themeColors.black,
			'&:hover': {
				backgroundColor: themeColors.grey800,
			},
		},
	},
	{
		props: {
			variant: 'text',
			color: 'greyScale',
		},
		style: {
			color: themeColors.black,
			'&:hover': {
				color: themeColors.grey600,
			},
		},
	},
	{
		props: {
			variant: 'text',
			color: 'primary',
		},
		style: {
			'&:hover': {
				color: themeColors.green600,
			},
		},
	},
	{
		props: {
			disabled: true,
			variant: 'outlined',
		},
		style: {
			backgroundColor: themeColors.grey200,
			color: themeColors.grey400,
		},
	},
]

export const Button = (theme: Theme) => {
	return {
		MuiButton: {
			defaultProps: {
				disableElevation: true,
				variant: 'contained',
				color: 'primary',
				size: 'xLarge',
			},
			variants: [...buttonSizeStyles, ...buttonColorStyles],
		},
	}
}
