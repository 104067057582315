import { PATH } from '@bridge/core/constants/route-path'
import { Toast } from '@bridge/ui'

import { useRouter } from 'next/router'

const useRoute = () => {
	const { push } = useRouter()

	const routeMain = async () => {
		await push('/')
	}

	const routeBridgeStoryOutlink = () => {
		const newWindow = window.open('https://blog.naver.com/bridge_project', '_blank')
		if (!newWindow) {
			console.error('Failed to open window')
		}
	}

	const routePremiumConsultingFAQ = () => {
		const newWindow = window.open('https://veiled-lunaria-1bf.notion.site/1-1-FAQ-2d24ce39500844d2a398708eb2c36d29?pvs=4', '_blank')
		if (!newWindow) {
			console.error('Failed to open window')
		}
	}

	const routeAllCarePackFAQ = () => {
		const newWindow = window.open('https://veiled-lunaria-1bf.notion.site/1-1-All-Care-Pack-FAQ-9b55fe83a2be4f75a47ffbecde97893b?pvs=4', '_blank')
		if (!newWindow) {
			console.error('Failed to open window')
		}
	}

	const routeGeneralFAQ = () => {
		const newWindow = window.open('https://veiled-lunaria-1bf.notion.site/I-CUSTOMER-CENTER-f20d39c3cb5248588940210eab2976ce', '_blank')
		if (!newWindow) {
			console.error('Failed to open window')
		}
	}

	const routeLecture = async () => {
		await push('/special-lecture')
	}

	const routeConsultingSub = async () => {
		await push('/premium-1on1-consulting-service')
	}

	const routeLogin = async () => {
		await push(PATH.LOGIN)
	}

	const routeSignUp = async () => {
		await push('/sign-up')
	}

	const routeSignUpKizscl = async () => {
		await push('/api/auth/referral-sign-up/KIZSCL')
	}

	const routeSignUpDaekyo = async () => {
		await push('/api/auth/referral-sign-up/DAEKYO')
	}

	const routePricing = async () => {
		await push('/pricing')
	}

	const routeLicense = () => {
		const url = 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=' + '2648105097'
		window.open(url, 'bizCommPop', 'width=750, height=800;')
	}

	const routeService = async () => {
		await push({
			pathname: '/policy',
			query: {
				policyType: 'service',
			},
		})
	}

	const routePrivacy = async () => {
		await push({
			pathname: '/policy',
			query: {
				policyType: 'privacy',
			},
		})
	}

	const routeAppDownload = () => {
		Toast.show({ description: '페이지 준비중' })
	}

	return {
		routeMain,
		routeBridgeStoryOutlink,
		routePremiumConsultingFAQ,
		routeAllCarePackFAQ,
		routeGeneralFAQ,
		routeLecture,
		routeConsultingSub,
		routeLogin,
		routeSignUp,
		routeSignUpKizscl,
		routeSignUpDaekyo,
		routePricing,
		routeLicense,
		routeService,
		routePrivacy,
		routeAppDownload,
	}
}

export default useRoute
