import { Box, Button, Stack, Typography } from '@bridge/ui'
import Svg from 'app/features/component/svg'
import colors from 'app/colors'
import Header from 'app/features/component/header/Header'
import LogoButton from 'app/features/component/button/LogoButton'
import SafeAreaFlex from 'app/features/component/safe-area-flex/SafeAreaFlex'
import useWebDimensions from 'app/utils/useWebDimensions'
import WordBreakText from '@bridge/ui/components/WordBreakText'

type ErrorContainerProps = {
	onConfirm?: () => void
	title: string
	message: string
}

export default function ErrorContainer ({
	message,
	title,
	onConfirm,
}: ErrorContainerProps) {
	const { width } = useWebDimensions()

	return (
		<>
			<Header
				theme="white"
				leftButton={<LogoButton logoType="black" />}
			/>
			<SafeAreaFlex>
				<Stack
					width={width}
					margin="auto"
					alignItems="center"
					justifyContent="center"
				>
					<Box mb='20px'>
						<Svg
							width={24}
							height={24}
							src="/meeting-room/fail.png"
							asset={require('app/assets/meeting-room/fail.png')}
						/>
					</Box>
					<Typography color={colors.grey900} variant="h7_600">
						{title}
					</Typography>
					<WordBreakText
						color={colors.grey600}
						variant="p_m_400"
						textAlign="center"
					>
						{message.split('\\n').join('\n')}
					</WordBreakText>

					{onConfirm && (
						<Button
							sx={{ width: width - 48, mt: '40px' }} // 좌 우 24px 크기만큼 width에서 빼줍니다. (마진이 부모 width를 넘어가서 마진으로 적용이 안되는 상황입니다.)
							size='large'
							onClick={onConfirm}
						>
							확인
						</Button>
					)}
				</Stack>
			</SafeAreaFlex>
		</>
	)
}
